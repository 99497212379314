.footercontainer{
    margin-top: 500px;
    background-color: #173e43;
}

.footertextstyle{
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin:0 auto;
    color: white;
}


.liststyle{
    padding: left 0;
}

.rowcontainer{
    margin:20px;
    padding: 50px;
}