.textstyle{
    text-align: center;
    font-size: large;
    font-family: sans-serif;
    margin: 40px;
}

.servicerowstyle{
    margin-bottom: 50px;
}

.card .imagestyle{
    border-radius: 10%;
    width: 100px;
    height: 100px;
    position: relative;
    left: 30%;
    margin-top: 10px;
    width: 40%;
    padding: 10px;
}

.servicewrapper{
    background-color: white;
}

.servicecardstyle{
    height:300px;
}

