.blogheader{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    height: 100px;
    padding: 20px;
    margin-bottom: 40px;
    margin-top: 40px;
}

.blogcontainer{
    background-color:#dddfd4;
    height: 300px;
    margin-top: 100px;
}

.blogstyle{
    margin: 0 auto;
    text-align: center;

}



.bodystyle.card-body{
    padding:30px;
}

.carousel-item{
    margin-bottom:100px;
}