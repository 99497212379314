.textwrapper{
    text-align: center;
    font-size: large;
    font-family: sans-serif;
    margin: 20px;
}

.testimonialstyle{
    text-align: center;
    font-size: large;
    font-family: sans-serif;
    padding:50px;
    width: 900px;
    margin: 0 auto;
}

