
.colstyle{
    margin-top:100px;
    }

.headtextstyle{
    text-align: left;
    color: black;
    font-family: sans-serif;
    margin-bottom: 20px;
}

.headertext{
    font-size: xxx-large;
    font-weight: bold;
    }

.containerstyle{
    padding-top: 10%;
    padding-bottom: 110px;
}

.divstyle{
    background-color: #fae596;
}

.headingstyle.jumbotron{
    background-color:  #fae596 ;
}